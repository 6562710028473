<template>
	<div v-if="authed">
        <v-data-table :headers="headers" :items="items"
			:options.sync="options" :server-items-length="totalItems"
			:loading="loading" loading-text="加载中...">
			<template v-slot:top>
				<v-row class="pl-4 pt-4 pb-2">
					<v-col :cols='2'>
						<v-select label="源" :items="initiators" v-model="initiator" dense hide-details @change="loadData"/>
					</v-col>
					<v-col :cols='2'>
						<v-select label="礼券类型" :items="origins" v-model="origin" clearable dense hide-details @change="loadData"/>
					</v-col>
					<v-col :cols='2'>
						<v-text-field label="备注" v-model="sscomment" clearable dense hide-details append-icon="search" @click:append="loadData"/>
					</v-col>
					<v-col cols="12" md="2">
						<v-checkbox v-model="showDiscarded" label="显示已作废" dense hide-details @click="loadData" class="pl-2"/>
					</v-col>
					<v-col :cols='2'>
						<v-btn v-if="admin" color="primary" @click="exportXLS" class="mx-1">导出</v-btn>
					</v-col>
				</v-row>
				<v-divider/>
            </template>
			<template v-slot:item.creationTime="{ item }">
				{{formatTime(item.creationTime)}}
			</template>
			<template v-slot:item.validFrom="{ item }">
				{{formatDate(item.validFrom)}}
			</template>
			<template v-slot:item.validTo="{ item }">
				{{formatDate(item.validTo)}}
			</template>
			<template v-slot:item.state="props">
				<v-edit-dialog v-if="props.item.state==='available'">
					{{props.item.state}}
					<v-icon color="primary" class="material-icons-outlined">delete_outline</v-icon>
					<template v-slot:input>
						<div style="text-align:center">
							<v-btn small color="primary" class="my-3" @click.stop="discard(props.item._id)">作废</v-btn>
						</div>
					</template>
				</v-edit-dialog>
				<template v-else>
					{{props.item.state}}
				</template>
			</template>
            <template v-slot:footer>
                <v-icon large class="ml-4 mt-3" style="position:absolute" @click.stop="dialog=true">mdi-plus</v-icon>
                <v-icon large class="mt-3" style="position:absolute;left:60px;" @click.stop="dialog2=true" v-if="admin">mdi-playlist-plus</v-icon>
            </template>
        </v-data-table>
		<v-dialog v-model="dialog" persistent max-width="300">
			<v-card>
				<v-card-title>创建礼券</v-card-title>
				<v-card-text>
					<v-form ref="couponform">
						<v-select :items="couponTpls" label="礼券类型" v-model="couponTpl"/>
						<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="pdate1" label="有效期起" readonly v-bind="attrs"
									v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
						</v-menu>
						<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="pdate2" label="有效期止" readonly v-bind="attrs"
									v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
						</v-menu>
						<v-select :items="consultants" label="健康顾问" item-text="name" v-model="consultant" return-object :rules="rules"/>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn @click="dialog=false">取消</v-btn>
					<v-btn color="primary" @click="save" :loading="loading" :disabled="loading">创建</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog2" persistent max-width="300">
			<v-card>
				<v-card-title>批量创建礼券</v-card-title>
				<v-card-text>
					<v-form ref="couponform2">
						<v-select :items="couponTpls2" label="礼券类型" v-model="couponTpl2"/>
						<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="pdate1" label="有效期起" readonly v-bind="attrs"
									v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
						</v-menu>
						<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="pdate2" label="有效期止" readonly v-bind="attrs"
									v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
						</v-menu>
						<v-text-field label="数量" type="number" v-model.number="amount" :rules="numberRules"/>
						<v-text-field label="备注" v-model="comment"/>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn @click="dialog2=false">取消</v-btn>
					<v-btn color="primary" @click="save2" :loading="loading" :disabled="loading">创建</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import {formatTime, formatDate, nowOffsetHours} from '../utils'
	import XLSX from 'xlsx-js-style'

	const couponTpls = [
		{text:'JT优惠券',    value:{origin:'JT优惠券', days:2, product:'bt', discount:2000}},
		{text:'50元优惠券',  value:{origin:'全品类券', days:30, product:'*', discount:50}},
		{text:'150元优惠券', value:{origin:'全品类券', days:30, product:'*', discount:150}},
		{text:'350元优惠券', value:{origin:'全品类券', days:30, product:'*', discount:350}},
	];
	const couponTpls2 = [
		{text:'ZT产品券', value:{origin:'专家精查', days:365, product:'zt'}},
		{text:'BT产品券', value:{origin:'标准精查', days:365, product:'bt'}},
		{text:'JT产品券', value:{origin:'特惠精查', days:365, product:'bt'}},
	];

    export default {
        data() {
            return {
				authed: false,
				dialog: false,
				pdate1: '',
				menu1: false,
				pdate2: '',
				menu2: false,
                headers: [
					{text:'券码', value:'_id', width:184, cellClass:"vouchercode"},
					{text:'礼券类型', value:'origin', width:130},
					{text:'可用产品', value:'product', width:100},
					{text:'优惠金额', value:'discount', width:100},
					{text:'有效期起', value:'validFrom', width:120},
					{text:'有效期止', value:'validTo', width:120},
					{text:'备注', value:'comment', width:150},
					{text:'健康顾问', value:'consultantName', width:100},
					{text:'绑定证件', value:'idcard', width:150},
					{text:'创建时间', value:'creationTime', width:170},
					{text:'创建者', value:'creator', width:120},
					{text:'状态', value:'state', width:150},
                ],
                items: [],
				couponTpls,
				couponTpl: couponTpls[0].value,
				consultants: [],
				consultant: null,
				rules: [v => !!v || '此项为必选'],
				loading: false,
				options: {sortBy:['creationTime'], sortDesc:['desc']},
				totalItems: 0,
				initiator: 'HS',
				showDiscarded: false,
				initiators: [{text:'华三',value:'HS'}, {text:'华兆',value:'HZ'}, {text:'礼品卡',value:'HV'}],
				admin: false,
				dialog2: false,
				couponTpls2,
				couponTpl2: couponTpls2[0].value,
				amount: 1,
				comment: '',
				sscomment: '',
				numberRules: [v => (/^[0-9]+$/g).test(v) || '只允许数字'],
				origin: '',
				origins: []
            }
        },
		mounted() {
			this.authed = this.$hasPrivilege('礼券');
			if (!this.authed) return;
			this.admin = this.$hasPrivilege('admin');
			this.formatTime = formatTime;
			this.formatDate = formatDate;
			this.pdate1 = formatDate(Date.now() + nowOffsetHours * 3600 * 1000 );
			this.pdate2 = formatDate(Date.now() + nowOffsetHours * 3600 * 1000 + 2 * 86400 * 1000);
			this.fetchConsultants();
			this.fetchData();
        },
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			couponTpl(n) {
				this.pdate2 = formatDate(Date.now() + nowOffsetHours * 3600 * 1000 + n.days * 86400 * 1000);
			},
			couponTpl2(n) {
				this.pdate2 = formatDate(Date.now() + nowOffsetHours * 3600 * 1000 + n.days * 86400 * 1000);
			},
		},
		methods: {
			async loadData() {
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
			async fetchData() {
				const filter = this.getFilter();
				const db = this.$tcbapp.database();
				this.loading = true;
				try {
					const r0 = await db.collection('wp2voucher').aggregate().group({_id: '$origin'}).end();
					this.origins = r0.data.map(x => x._id);
                    const countRes = await db.collection('wp2voucher').where(filter).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2voucher').where(filter).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			getFilter() {
				const f = {
					initiator: this.initiator,
				}
				if (this.origin) {
					f.origin = this.origin;
				}
				if (!this.showDiscarded) {
					const db = this.$tcbapp.database();
					const _ = db.command;
					f.state = _.neq('discarded');
				}
				if (this.sscomment) {
					f.comment = this.sscomment;
				}
				return f;
			},
			async fetchConsultants() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				try {
					const res = await db.collection('consultants').field({name:true}).where({rank:_.gt(0)}).orderBy('rank','desc').limit(50).get();
					this.consultants = res.data;
				} catch(err) {
					console.error(err);
				}
			},
			async save() {
				if (!this.$refs['couponform'].validate()) return;
				this.loading = true;
				try {
					await this.$tcbapp.callFunction({name:"call",
						data:{
							function: 'addCoupon2',
							data: {
								origin: this.couponTpl.origin,
								validFrom: this.pdate1,
								validTo: this.pdate2,
								region: ['*'],
								product: this.couponTpl.product,
								discount: this.couponTpl.discount,
								consultantId: this.consultant._id,
								consultantName: this.consultant.name,
							}
						}
					});
					await this.fetchData();
	                this.dialog = false;
				} catch(err) {
					console.error(err);				
				}
				this.loading = false;
			},
			async save2() {
				if (!this.$refs['couponform2'].validate()) return;
				this.loading = true;
				try {
					await this.$tcbapp.callFunction({name:"call",
						data:{
							function: 'addCoupon3',
							data: {
								origin: this.couponTpl2.origin,
								validFrom: this.pdate1,
								validTo: this.pdate2,
								region: ['*'],
								product: this.couponTpl2.product,
								amount: this.amount,
								comment: this.comment
							}
						}
					});
					await this.fetchData();
	                this.dialog2 = false;
				} catch(err) {
					console.error(err);				
				}
				this.loading = false;
			},
			async discard(code) {
				this.loading = true;
				try {
					const res = await this.$tcbapp.callFunction({name:"call",
						data:{
							function: 'discardVoucher',
							data: {
								code
							}
						}
					});
					this.fetchData();
				} catch(err) {
					console.error(err);				
				}
				this.loading = false;
			},
			async exportXLS() {
				const MAX_LIMIT = 100;
				const filter = this.getFilter();
				const db = this.$tcbapp.database();
				const countResult = await db.collection('wp2voucher').where(filter).count();
				const total = countResult.total;
				const batchTimes = Math.ceil(total / MAX_LIMIT);
				const tasks = [];
				for (let i = 0; i < batchTimes; i++) {
					const promise = db.collection('wp2voucher').where(filter).skip(i * MAX_LIMIT).limit(MAX_LIMIT).get();
					tasks.push(promise);
				}
				const res = (await Promise.all(tasks)).reduce((acc, cur) => {
					return {
						data: acc.data.concat(cur.data),
						errMsg: acc.errMsg,
					}
				});
				const header = [[
					'券码',
					'礼券类型',
					'可用产品',
					'优惠金额',
					'有效期起',
					'有效期止',
				]];
				const data = res.data.map(x => [
					x._id,
					x.origin,
					x.product,
					x.discount || '',
					formatDate(x.validFrom),
					formatDate(x.validTo),
				]);
				const filename = "WP礼券导出.xlsx";
				const ws_name = '礼券';
				const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(header.concat(data), {dateNF: 'YYYY-MM-DD HH:mm:ss'});
				XLSX.utils.book_append_sheet(wb, ws, ws_name);
				XLSX.writeFile(wb, filename);
			},
        }
    }
</script>

<style>
	.vouchercode {
		font-family: monospace, monospace;
		font-weight: bold;
	}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authed)?_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"loading-text":"加载中..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pl-4 pt-4 pb-2"},[_c('v-col',{attrs:{"cols":2}},[_c('v-select',{attrs:{"label":"源","items":_vm.initiators,"dense":"","hide-details":""},on:{"change":_vm.loadData},model:{value:(_vm.initiator),callback:function ($$v) {_vm.initiator=$$v},expression:"initiator"}})],1),_c('v-col',{attrs:{"cols":2}},[_c('v-select',{attrs:{"label":"礼券类型","items":_vm.origins,"clearable":"","dense":"","hide-details":""},on:{"change":_vm.loadData},model:{value:(_vm.origin),callback:function ($$v) {_vm.origin=$$v},expression:"origin"}})],1),_c('v-col',{attrs:{"cols":2}},[_c('v-text-field',{attrs:{"label":"备注","clearable":"","dense":"","hide-details":"","append-icon":"search"},on:{"click:append":_vm.loadData},model:{value:(_vm.sscomment),callback:function ($$v) {_vm.sscomment=$$v},expression:"sscomment"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-checkbox',{staticClass:"pl-2",attrs:{"label":"显示已作废","dense":"","hide-details":""},on:{"click":_vm.loadData},model:{value:(_vm.showDiscarded),callback:function ($$v) {_vm.showDiscarded=$$v},expression:"showDiscarded"}})],1),_c('v-col',{attrs:{"cols":2}},[(_vm.admin)?_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary"},on:{"click":_vm.exportXLS}},[_vm._v("导出")]):_vm._e()],1)],1),_c('v-divider')]},proxy:true},{key:"item.creationTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.creationTime))+" ")]}},{key:"item.validFrom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.validFrom))+" ")]}},{key:"item.validTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.validTo))+" ")]}},{key:"item.state",fn:function(props){return [(props.item.state==='available')?_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('v-btn',{staticClass:"my-3",attrs:{"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.discard(props.item._id)}}},[_vm._v("作废")])],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.state)+" "),_c('v-icon',{staticClass:"material-icons-outlined",attrs:{"color":"primary"}},[_vm._v("delete_outline")])],1):[_vm._v(" "+_vm._s(props.item.state)+" ")]]}},{key:"footer",fn:function(){return [_c('v-icon',{staticClass:"ml-4 mt-3",staticStyle:{"position":"absolute"},attrs:{"large":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog=true}}},[_vm._v("mdi-plus")]),(_vm.admin)?_c('v-icon',{staticClass:"mt-3",staticStyle:{"position":"absolute","left":"60px"},attrs:{"large":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog2=true}}},[_vm._v("mdi-playlist-plus")]):_vm._e()]},proxy:true}],null,false,675156071)}),_c('v-dialog',{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("创建礼券")]),_c('v-card-text',[_c('v-form',{ref:"couponform"},[_c('v-select',{attrs:{"items":_vm.couponTpls,"label":"礼券类型"},model:{value:(_vm.couponTpl),callback:function ($$v) {_vm.couponTpl=$$v},expression:"couponTpl"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":10,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"有效期起","readonly":""},model:{value:(_vm.pdate1),callback:function ($$v) {_vm.pdate1=$$v},expression:"pdate1"}},'v-text-field',attrs,false),on))]}}],null,false,657429661),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.pdate1),callback:function ($$v) {_vm.pdate1=$$v},expression:"pdate1"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":10,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"有效期止","readonly":""},model:{value:(_vm.pdate2),callback:function ($$v) {_vm.pdate2=$$v},expression:"pdate2"}},'v-text-field',attrs,false),on))]}}],null,false,4190003691),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.pdate2),callback:function ($$v) {_vm.pdate2=$$v},expression:"pdate2"}})],1),_c('v-select',{attrs:{"items":_vm.consultants,"label":"健康顾问","item-text":"name","return-object":"","rules":_vm.rules},model:{value:(_vm.consultant),callback:function ($$v) {_vm.consultant=$$v},expression:"consultant"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.dialog=false}}},[_vm._v("取消")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v("创建")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c('v-card',[_c('v-card-title',[_vm._v("批量创建礼券")]),_c('v-card-text',[_c('v-form',{ref:"couponform2"},[_c('v-select',{attrs:{"items":_vm.couponTpls2,"label":"礼券类型"},model:{value:(_vm.couponTpl2),callback:function ($$v) {_vm.couponTpl2=$$v},expression:"couponTpl2"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":10,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"有效期起","readonly":""},model:{value:(_vm.pdate1),callback:function ($$v) {_vm.pdate1=$$v},expression:"pdate1"}},'v-text-field',attrs,false),on))]}}],null,false,657429661),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.pdate1),callback:function ($$v) {_vm.pdate1=$$v},expression:"pdate1"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":10,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"有效期止","readonly":""},model:{value:(_vm.pdate2),callback:function ($$v) {_vm.pdate2=$$v},expression:"pdate2"}},'v-text-field',attrs,false),on))]}}],null,false,4190003691),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.pdate2),callback:function ($$v) {_vm.pdate2=$$v},expression:"pdate2"}})],1),_c('v-text-field',{attrs:{"label":"数量","type":"number","rules":_vm.numberRules},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=_vm._n($$v)},expression:"amount"}}),_c('v-text-field',{attrs:{"label":"备注"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.dialog2=false}}},[_vm._v("取消")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.save2}},[_vm._v("创建")])],1)],1)],1)],1):_c('div',[_vm._v(" 无此权限 ")])}
var staticRenderFns = []

export { render, staticRenderFns }